import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { Package, Props as PackageProps } from './Package'

export interface Props {
  languageCode: string
  packages: PackageProps[]
}

export const PackagesList = memo(function PackagesList({
  languageCode,
  packages,
}: Props) {
  if (packages.length < 1) {
    return (
      <Container>
        <Message>
          {useVocabularyData('no-offers-message', languageCode)}
        </Message>
      </Container>
    )
  }

  const [loading, setloading] = useState(true)

  const [containerRef, isContainerVisible] = useInView({
    triggerOnce: true,
  })

  const variants = {
    visible: (i: any) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.3,
        duration: 0.6,
        stiffness: 1000,
        velocity: -100,
      },
    }),
    hidden: { opacity: 0, x: -20 },
  }

  useEffect(() => {
    setTimeout(() => {
      setloading(false)
    }, 1000)
  }, [])

  return (
    <Container ref={containerRef}>
      <List row stretch space="between" wrap>
        {packages.map((item: any, index: any) => (
          <FadeInUp delay={index * 0.1 + (loading ? 2.3 : 0)} key={index}>
            <Package
              animate={isContainerVisible ? 'visible' : 'hidden'}
              className={!loading ? 'loaded' : null}
              custom={index}
              variants={variants}
              {...item}
            />
          </FadeInUp>
        ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  text-align: center;
  position: relative;

  @media (min-width: 1200px) {
    &:before {
      content: '';
      position: absolute;
      top: -17.5rem;
      left: 0;
      width: 75%;
      height: 75%;
      background-image: url('/instagram_bg.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
    }
  }
`

const List = styled(FlexBox)`
  gap: 6rem 0;
  margin: 7.5rem auto 12.5rem;
  padding: 0 8.938vw;

  > div {
    width: calc(33.333% - 3.313vw);
  }

  @media (max-width: 1199px) {
    padding: 0 1.5625rem;
    margin: 3rem auto 6.25rem;
  }

  @media (max-width: 767px) {
    gap: unset;
    margin: 3.75rem auto 5.625rem;

    > div {
      width: 100%;
      margin-top: 3.75rem;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
`

const Message = styled.div`
  margin-top: 5.625rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 11.625rem 10.625vw;
  text-align: center;

  @media (max-width: 767px) {
    padding: 7.5rem 1.875rem;
  }
`
